/* eslint-disable camelcase */
export interface BreadcrumbRoute {
  path?: string;
  breadcrumbName: string;
  children?: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}
export type PositionType = 'athlete' | 'staff' | 'team';
export enum FootballPosition {
    guard = 'g',
    center = 'c',
    //   defender = 'df',
    forward = 'f',
}
export interface QueryParams {
  page?: number;
  size?: number;
  name?: string;
  minAge?: number;
  maxAge?: number;
  gender?: number;
  nationality?: string
  global_config_positions?: string
  global_config_departments?: string
}
export interface BaseResponse<DT> {
  success: boolean;
  message: string;
  data: DT;
}
export interface BasePagination<DT> {
  [key: string]: {
    currentPage: number;
    total: number;
    perPage: number;
    lastPage: number;
    last_updated: Date;
    data: DT;
  };
}
export interface BaseSingleResponse<DT> {
  [key: string]: DT;
}
export interface BaseData {
  id: number;
  active: boolean;
  created_at: Date;
  created_by: string;
  updated_at: Date;
  updated_by: string | null;
  deleted: boolean;
}
export interface Media extends BaseData {
  setting_team_media_file_name: string | null;
  setting_team_media_profile_url: string | null;
  setting_team_media_profile_icon: string | null;
  setting_team_media_profile_avatar: string | null;
  setting_team_media_size: number;
  setting_team_media_mimetype: string;
  setting_team_media_type: number;
  // setting_teams: number
}
export interface Match {
  id: number;
  setting_stadiums: number;
  versus_match_stadium_holder: number;
  versus_match_date: Date; // 2021-08-01,
  versus_match_start_at: string; // Time //00:23:00,
  versus_match_end_at: string;
  competition_sections: {
    id: number;
    competition_section_name_th: string;
    competition_section_name_en: string; // Third Round
  };
  versus_match_team_detail_one_id: {
    versus_match_team_id: number;
    versus_match_team_name: string | null;
    versus_match_team_image: string | null;
    versus_match_score: number;
  };
  versus_match_team_detail_two_id: {
    versus_match_team_id: number;
    versus_match_team_name: string | null;
    versus_match_team_image: string | null;
    versus_match_score: number;
  };
  setting_sub_competition: {
    id: number;
    setting_sub_competition_name_th: string;
    setting_sub_competition_name_en: string;
    setting_sub_competition_media_profile_url: string | null;
  };
}
export type ListVersusMatch = Omit<
  Match,
  | 'setting_stadiums'
  | 'versus_match_stadium_holder'
  | 'competition_sections'
  | 'versus_match_start_at'
  | 'versus_match_end_at'
>;
export interface LastestMatch {
  latest_versus_match?: Match[];
  list_versus_match?: ListVersusMatch[];
}
export interface Team extends BaseData {
  global_config_local_type_name_en: string;
  global_config_local_type_name_th: string;
  setting_team_information_name_en: string;
  setting_team_information_name_th: string;
  setting_team_media_profile_url: string;
  team_history_status: number;
  team_id: number;
}

export interface Information {
  account_information_firstname_th: string;
  account_information_lastname_th: string;
  account_information_firstname_en: string;
  account_information_lastname_en: string;
  account_information_blood_type: string;
  account_information_dob: string;
  account_information_nationality: string;
  account_information_height: number;
  account_information_weight: string;
  account_information_biography_th: string;
  account_information_biography_en: string;
  account_media_preview_url: string;
  account_media_preview_path: string;
  account_information_age: number;
}

export interface Contacts {
  id: string;
  account_social_contact_facebook: string;
  account_social_contact_email: string;
  account_social_contact_line: string;
  account_social_contact_instagram: string;
  account_social_contact_youtube: string;
  account_social_contact_website: string;
  account_social_contact_fax: string;
}

export interface Educations {
  id: string;
  global_config_education_name_th: string;
  global_config_education_name_en: string;
}

export interface TeamInfo {
  team: {
    setting_team_informations: {
      setting_team_information_abbreviation: string;
      setting_team_information_name_th: string;
      setting_team_information_name_en: string;
      setting_team_information_nickname: string;
      setting_team_information_established: string;
      setting_team_information_history_th: string | null;
      setting_team_information_history_en: string | null;
      setting_team_information_remark: string | null;
      medias: Media;
    };
  };
}

interface TechnicalSkills {
  account_technical_skill_percentage: string;
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  id: string;
  updated_at: string;
  updated_by: string;
  global_technical_skill_id: {
    active: boolean;
    created_at: string;
    created_by: string;
    deleted: boolean;
    id: string;
    updated_at: string;
    updated_by: string;
    global_config_skill_name_th: string
    global_config_skill_name_en: string
  }
}
export interface IAthlete {
  id: number;
  name: { en: string; th: string };
  age: number;
  positions: { [key: string]: string }[];
  team: {
    club: { en: string; th: string };
    national: { en: string; th: string };
    logo?: { club: string; national: string };
  };
  nationallity: { en: string; th: string };
  avatar: unknown;
  fitness: {
    percentage: number; suggestion: {
      en: string,
      th: string
    }
  };
  teamsId: number[];
  technical_skills?: Array<TechnicalSkills>
  injuries?: {
    level: string
    name: { en: string; th: string };
    number: string
    type: string
  },
  nationality: string,
  birthday: string
  weight: string | number
  height: string | number
}
export type IStaff = Omit<IAthlete, 'team' | 'fitness'>;

export interface UserAccount {
  account_information_firstname_th: string;
  account_information_lastname_th: string;
  account_information_firstname_en: string | null;
  account_information_lastname_en: string | null;
  account_information_dob: Date;
  account_information_nationality: string;
  account_information_nationality_en: string;

  account_media_preview_url: string;
  account_information_age: number;
  account_information_biography_th?: string;
  account_information_biography_en?: string;

  account_information_height?: number;
  account_information_weight?: number;
  account_information_blood_type?: string | null;
}

export interface UserInjuries {
  account_id: string;
  account_injury_admitted_begin_at: string;
  account_injury_admitted_end_at: string;
  account_injury_cause: string;
  account_injury_cost: string;
  account_injury_date: string;
  account_injury_doctor_name: string;
  account_injury_doctor_name_en: string;
  account_injury_level: string;
  account_injury_medical: string;
  account_injury_medical_en: string;
  account_injury_name_en: string;
  account_injury_name_th: string;
  account_injury_present: boolean;
  account_injury_recovery_time: string;
  account_injury_recovery_time_unit: string;
  account_injury_remark: string;
  account_injury_remark_en: string;
  account_injury_status: string;
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  id: string;
  updated_at: string;
  updated_by: string;
}
export interface AccountSpecification extends BaseData {
  global_config_positions: unknown[];
  global_config_departments: null;
  global_config_sport_categories: unknown[];
  account_specification_main_position: string;
  account_physical_fitness: null;
  account_honors: null;
  account_technical_skills: unknown[];
}
export interface PlayerPosition extends BaseData {
  global_config_position_name_th: string;
  global_config_position_name_en: string;
  global_config_position_abbreviation: string;
  global_config_position_type: number;
  global_config_position_main_position: string;
}

export interface StaffDepartment {
  global_config_department_name_en: string
  global_config_department_name_th: string
  id: string
}
export interface PlayerHealth extends BaseData {
  global_config_physical_fitness_percentage: number;
  global_config_physical_fitness_name_th: string;
  global_config_physical_fitness_name_en: string | null;
}
export interface Athlete {
  account_id: number;
  account_specifications: null;
  account_informations: UserAccount;
  account_injuries: UserInjuries[];
  team_club: Team | null;
  team_national: Team | null;
  global_config_positions: PlayerPosition[];
  global_config_physical_fitness: PlayerHealth;
  technical_skills?: Array<{}>

}

export interface AthleteInformation {
  data: any;
  account_id: number;
  account_specifications: string;
  account_informations: Information | null;
  social_contacts: Contacts | null;
  midical_conditions: Array<{
    id: string;
    global_config_educations: Educations | null;
    account_education_name_th: string;
    account_education_name_en: string;
    account_education_faculty: string;
    account_education_faculty_en: string
    account_education_end_at: string;
    account_education_current: string;
  }>;
}
interface GlobalConfigInjuryTypes {
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  global_config_injury_type_description_en: string;
  global_config_injury_type_description_th: string;
  global_config_injury_type_name_en: string;
  global_config_injury_type_name_th: string;
  id: string;
  updated_at: string;
  updated_by: string;
}

export interface TeamPlayerHistory {
  accounts: object;
  global_config_positions: string;
  id: string;
  setting_teams: object;
  team_player_history_begin_at: string;
  team_player_history_end_at: string;
  team_player_history_loan: string;
  team_player_history_match_count: string;
  team_player_history_shirt_number: string;
  team_player_history_status: Number;
}

export interface HonorsNation {
  honor_name: String;
  honor_year: Number;
  id: Number;
  setting_sub_competition_name_en: String;
  setting_sub_competition_name_th: String;
  setting_team_name_en: String;
  setting_team_name_th: String;
}

export interface InjuriesCurrent {
  account_id: string;
  account_injury_admitted_begin_at: string;
  account_injury_admitted_end_at: string;
  account_injury_cause: string;
  account_injury_cost: string;
  account_injury_date: string;
  account_injury_doctor_name: string;
  account_injury_doctor_name_en: string;
  account_injury_level: string;
  account_injury_medical: string;
  account_injury_medical_en: string;
  account_injury_name_en: string;
  account_injury_name_th: string;
  account_injury_present: boolean;
  account_injury_recovery_time: string;
  account_injury_recovery_time_unit: string;
  account_injury_remark: string;
  account_injury_remark_en: string;
  account_injury_status: number;
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  global_config_injury_types: GlobalConfigInjuryTypes;
  id: string;
  updated_at: string;
  updated_by: string;
}

export interface PenaltiesCurrent {
  account_id: string;
  account_penalty_appeal_begin: string;
  account_penalty_appeal_conclusion: boolean;
  account_penalty_appeal_duration: string;
  account_penalty_appeal_duration_unit: string;
  account_penalty_competition_match: string;
  account_penalty_competition_match_en: string;
  account_penalty_competition_name: string;
  account_penalty_date: string;
  account_penalty_description_en: string;
  account_penalty_description_th: string;
  account_penalty_duration: string;
  account_penalty_duration_unit: string;
  account_penalty_present: boolean;
  account_penalty_title_en: string;
  account_penalty_title_th: string;
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  id: string;
  updated_at: string;
  updated_by: string;
}

export interface subMedia {
  account_id: string;
  account_media_document_type: string;
  account_media_file_name: string;
  account_media_mimetype: string;
  account_media_name: string;
  account_media_size: string;
  account_media_type: number;
  account_media_url: string;
  account_media_video_url: string;
  id: string;
  active: boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  updated_at: string;
  updated_by: string;
}
export interface media {
  currentPage: number;
  data: Array<subMedia>;
  lastPage: number;
  next: number;
  perPage: number;
  previous: number;
  total: number;
}

export interface GlobalConfigPositions {
  active: Boolean;
  created_at: string;
  created_by: string;
  deleted: boolean;
  global_config_position_abbreviation: string;
  global_config_position_main_position: string;
  global_config_position_name_en: string;
  global_config_position_name_th: string;
  global_config_position_type: number;
  id: string;
  updated_at: string;
  updated_by: string;
}
interface AccountEducation {
  account_education_current: boolean;
  account_education_end_at: string;
  account_education_faculty: string;
  account_education_faculty_en: string
  account_education_name_en: string;
  account_education_name_th: string;
  global_config_educations: {
    global_config_education_name_en: string;
    global_config_education_name_th: string;
    id: number;
  };
}

export interface AthleteTeamHistory {
  currentPage: Number;
  data: Array<TeamPlayerHistory>;
  global_config_positions: Array<GlobalConfigPositions>;
  lastPage: Number;
  perPage: Number;
  total: Number;
}

export interface AthleteHonors {
  currentPage: Number;
  data: Array<HonorsNation>;
  lastPage: Number;
  perPage: Number;
  total: Number;
}





export interface AthleteInjuriesCurrent {
  data: Array<InjuriesCurrent>;
}

export interface AthletePenaltiesCurrent {
  data: Array<PenaltiesCurrent>;
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface AthleteMedia {
  media: media;
}
interface MedicalCondition {
  account_medical_condition_name_en: null;
  account_medical_condition_name_th: null;
  account_medical_condition_priority: null;
  account_medical_condition_type:
    | 'FOOD_ALLERGY'
    | 'DRUG_ALLERGY'
    | 'CONGENTIAL_DISEASE';
  id: number;
}
interface AccountSocialContact {
  account_social_contact_email: string | null;
  account_social_contact_facebook: string | null;
  account_social_contact_fax: string | null;
  account_social_contact_instagram: string | null;
  account_social_contact_twitter: string | null;
  account_social_contact_line: string | null;
  account_social_contact_website: string | null;
  account_social_contact_youtube: string | null;
  id: number;
}
export interface Staff
  extends Pick<
    Athlete,
    'account_id' | 'account_specifications' | 'account_informations'
  > {
  global_config_positions: PlayerPosition[];
  global_config_departments: StaffDepartment
  team_club: Team;
  team_national: Team;
  account_education: AccountEducation[];
  midical_conditions: MedicalCondition[];
  social_contacts: AccountSocialContact;
}
export type BannerInfoFilter = 'banner' | 'info';
export interface AthleteParam extends QueryParams {
  injuries?: boolean;
}
export interface AthleteQueryDto extends QueryParams {
  type?: string;
  name?: string;
  minAge?: number;
  maxAge?: number;
  gender?: any
  nationality?: string;
  provinces?: string;
  account_injury_level?: string
}

interface SettingTeamInfo {
  setting_team_information_name_th: string;
  setting_team_information_name_en: string | null;
  setting_team_information_abbreviation: string | null;
  setting_team_information_established: string | null;
  setting_team_information_nickname: string | null;
  setting_team_media_profile_url: string | null;
  setting_team_information_history_th: string | null;
}
interface GCLocalType {
  id: number;
  global_config_standard_types: number;
  global_config_local_type_name_th: string;
  global_config_local_type_name_en: string;
}
interface Country {
  country_name_th: string | null;
  country_name_en: string | null;
  district_name_en: string | null;
  province_name_th: string | null;
  setting_team_region_zipcode: string | null;
  sub_district_name_th: string | null;
  setting_stadium_region_address_detail: string | null;
  setting_stadium_region_address_detail_en: string | null
}
interface SocialContact {
  setting_team_social_contact_email: string | null;
  setting_team_social_contact_facebook: string | null;
  setting_team_social_contact_fax: string | null;
  setting_team_social_contact_line: string | null;
  setting_team_social_contact_phone: string | null;
  setting_team_social_contact_instagram: string | null;
  setting_team_social_contact_twitter: string | null;
  setting_team_social_contact_website: string | null;
  setting_team_social_contact_youtube: string | null;
}

interface RegionName {
  country_name_en: string;
  country_name_th: string;
  district_name_en: string;
  district_name_th: string;
  province_name_en: string;
  province_name_th: string;
  setting_stadium_region_address_detail: string;
  setting_stadium_region_address_detail_en: string
  setting_stadium_region_zipcode: string;
  sub_district_name_en: string;
  sub_district_name_th: string;
}
export interface TeamDetail {
  id: number;
  setting_team_informations: SettingTeamInfo;
  global_config_local_types: GCLocalType;
  setting_team_region_country: Country;
  setting_team_social_contacts?: SocialContact;
  setting_team_regions_name?: RegionName
}

interface TeamStaffCurrent {
  accounts: Athlete;
  global_config_department: string;
  global_config_positions: string;
  id: string;
  setting_teams: TeamDetail;
  team_staff_history_begin_at: string
  team_staff_history_end_at: string
  team_staff_history_status: number
}

export interface TeamStaff {
  currentPage: Number;
  data: Array<TeamStaffCurrent>;
  lastPage: Number;
  perPage: Number;
  total: Number;
  previous?: Number;
  next?: Number;
  last_updated?: String;
}
export interface TeamUniform {
  created_at: string;
  created_by: string;
  setting_team_uniform_image_mimetype: string;
  setting_team_uniform_image_name: string;
  setting_team_uniform_image_size: string;
  setting_team_uniform_image_type: number;
  setting_team_uniform_image_url: string;
  setting_team_uniform_type: string;
  setting_team_year: string;
  setting_teams: string
  updated_at: string;
  updated_by: string
}

// export interface
export interface IContact {
  email?: string;
  facebook?: string;
  line?: string;
  instagram?: string;
  youtube?: string;
  twitter?: string;
  website?: string;
  fax?: string;
  phone?: string
}
export interface TeamList {
  currentPage: number;
  data: TeamDetail;
  lastPage: number;
  last_updated: Date | string;
  next: number;
  perPage: number;
  previous: number;
  total: number;
}
export interface TeamQueryDto extends QueryParams {
  type?: 'local' | 'foreign';
  subType?: 'club' | 'nation';
  global_config_local_types?: number;
  total?: boolean
}
export interface ITeam {
  id: number | string;
  name: {
    en: string;
    th: string;
    abbreviation: any;
    established?: any;
    nickname: string;
  };
  logo: string | null;
  country_th: string;
  country_en: string;
  nationCategory?: string;
  nationCategoryEN?: string
  history: any;
  contact?: SocialContact;
  address: {
    address: string;
    subdistrict: string;
    district: string;
    province: string;
    country?: string;
    zipcode: string;
  };
}


export interface Teams {
  [key: string]: ITeam[];
}

interface PitchDetail extends BaseData {
  pitch_detail_width: string;
  pitch_detail_length: string;
  pitch_detail_grass: string;
}
interface SettingStadiumOwner extends BaseData {
  setting_stadium_owner_name_th: string;
  setting_stadium_owner_name_en: string;
  setting_stadium_owner_media_name: string;
  setting_stadium_owner_media_size: string;
  setting_stadium_owner_media_mimetype: string;
  setting_stadium_owner_media_url: string;
}
export interface SettingCountry extends BaseData {
  country_id: number;
  country_image: string;
  country_iso639_1: string;
  country_iso639_2: string;
  country_native1_name_th: string;
  country_native1_name_en: string;
  country_native2_name_th: string;
  country_native2_name_en: string;
}
export interface SettingProvince extends BaseData {
  province_code: number;
  province_name_th: string;
  province_name_en: string;
  country_id: number;
}
interface SettingDistrict extends BaseData {
  district_code: number;
  district_name_th: string;
  district_name_en: string;
  province_id: number;
}
interface SettingSubDistrict extends BaseData {
  district_code: number;
  district_name_th: string;
  district_name_en: string;
  province_id: number;
}
interface SettingRegion extends BaseData {
  setting_stadium_region_country_id: SettingCountry;
  setting_stadium_region_address_detail: string;
  setting_stadium_region_address_detail_en: string
  setting_stadium_region_province_id: SettingProvince;
  setting_stadium_region_district_id: SettingDistrict;
  setting_stadium_region_subdistrict_id: SettingSubDistrict;
  setting_stadium_region_zipcode: string;
}
 export interface SettingStadiumSocialContact extends BaseData {
  setting_stadium_social_contact_email: string;
  setting_stadium_social_contact_facebook: string;
  setting_stadium_social_contact_fax: string;
  setting_stadium_social_contact_instagram: string;
  setting_stadium_social_contact_line: string;
  setting_stadium_social_contact_phone: string;
  setting_stadium_social_contact_twitter: string;
  setting_stadium_social_contact_website: string;
  setting_stadium_social_contact_youtube: string;
}
export interface Stadium extends BaseData {
  pitch_details: PitchDetail;
  setting_stadium_owners: SettingStadiumOwner;
  setting_stadium_social_contacts: number | SettingStadiumSocialContact;
  setting_stadium_regions: SettingRegion;
  setting_stadium_regions_name?: RegionName;
  setting_stadium_name_th: string;
  setting_stadium_name_en: string;
  setting_stadium_capacity: number;
  setting_stadium_map: null;
  setting_stadium_history_th: string;
  setting_stadium_history_en: string;
}

export interface IStadium {
  id: number;
  name: { en: string; th: string };
  image: string;
  country: { en: string; th: string };
  province: { en: string; th: string };
  capacity: number;
  owner: { en: string; th: string };
  pitch: {
    width: string | null;
    length: string | null;
    type: string | null;
  };
  history: { en: string; th: string };
  address: { en: string; th: string };
  contact: IContact;
}

export interface StadiumQueryDto extends QueryParams {
  name?: string;
  setting_team_region_country_id?: number;
  setting_team_region_province_id?: number;
}
interface SettingCompetitionInfo {
  setting_competition_information_code: string;
  setting_competition_information_name_th: string;
  setting_competition_information_name_en: string;
  setting_competition_information_organizer_th: string;
  setting_competition_information_organizer_en: string;
  setting_competition_information_abbreviation: string;
  setting_competition_information_history_th: string;
  setting_competition_information_history_en: string;
  setting_competition_media_profile_url: string; // T_T
}
interface GCStandardType {
  id: number;
  global_config_standard_type_name_th: string;
  global_config_standard_type_name_en: string;
}

export interface CompetitionContact extends BaseData {
  setting_competition_social_contact_email: string;
  setting_competition_social_contact_facebook: string;
  setting_competition_social_contact_fax: string;
  setting_competition_social_contact_instagram: string;
  setting_competition_social_contact_line: string;
  setting_competition_social_contact_phone: string;
  setting_competition_social_contact_twitter: string;
  setting_competition_social_contact_website: string;
  setting_competition_social_contact_youtube: string;
}

export interface IAwardDetails {
  team_id?: number
  team_name_en?: string
  team_name_th?: string
  team_profile_url?: string
}
interface IAward {
  gold: IAwardDetails
  bronze: IAwardDetails
  silver: IAwardDetails
}
export interface Competition extends BaseData {
  setting_competition_informations: SettingCompetitionInfo;
  setting_competition_level: string;
  setting_competition_social_contacts: CompetitionContact;
  global_config_standard_types: GCStandardType;
  setting_competition_media_profile_icon: string;
  count_sub_competition: number;
}

export interface CompetitionQueryDto extends QueryParams {
  active?: boolean;
  name?: string;
}
export interface AtheteQueryOverviewDto extends QueryParams {
  injuries?: boolean
}

export interface CompetitionOverviewQuery extends QueryParams {
  competitions: boolean;
  latest: boolean
}

export interface TeamOverviewQuery extends QueryParams {
  global_config_local_types?: number,
  total?: boolean
}
export enum CompetitionType {
  GLOBAL = 'GLOBAL',
  INTERNATIONAL = 'INTERNATIONAL',
  CONTINENTAL = 'CONTINENTAL',
  REGIONAL = 'REGIONAL',
  NATIONAL = 'NATIONAL',
  ZONE = 'ZONE',
  DISTRICT = 'DISTRICT'
}
export interface Award {
  gold: any;
  silver: any;
  bronze: any;
}
export interface SubCompetition extends BaseData {
  setting_competition_id: number;
  setting_sub_competition_awards: Award;
  setting_sub_competition_abbreviation: string;
  setting_sub_competition_category: string;
  setting_sub_competition_category_en: string;
  setting_sub_competition_cohostname: string;
  setting_sub_competition_cohostname_en: string;
  setting_sub_competition_end_at: string;
  setting_sub_competition_history_en: string;
  setting_sub_competition_history_th: string;
  setting_sub_competition_hostname: string;
  setting_sub_competition_hostname_en: string;
  setting_sub_competition_media_profile_url: string;
  setting_sub_competition_media_profile_icon: string;
  setting_sub_competition_name_en: string;
  setting_sub_competition_name_th: string;
  setting_sub_competition_published: boolean;
  setting_sub_competition_social_contacts: null;
  setting_sub_competition_start_at: string;
  setting_sub_competition_type: string;
  setting_sub_competition_year: string;
}

export interface CompetitionInformation {
  code: string;
  level: string;
  type: CompetitionType | string;
  teamType: {
    th: string;
    en: string;
  }
  totalSubCompetition: number;
}

export interface ICompetition {
  id: number;
  name: { en: string; th: string };
  host: { en: string; th: string };
  logo: string;
  info: CompetitionInformation;
  history: { en: string; th: string };
  contact: IContact;
  year: string;
  startDate: string;
  endDate: string;
  contacts?: any
  type: { en: string; th: string };
  winner: { en: string; th: string };
  winnerLogo?: string
}


export interface IOverview {
  data: IAthlete[]
  lastUpdated: {
    th: string
    en: string
  }
}
export interface IOverviewTeam {
  data: ITeam[]
  lastUpdated: {
    th: string
    en: string
  }
}

export interface IOverviewCompetition {
  data: ICompetition[]
  lastUpdated: {
    th: string
    en: string
  }
}
export interface SubCompetitionQueryParamsDto extends QueryParams {
  name?: string;
}

export type TeamInSubCompetitionQueryParamsDto = SubCompetitionQueryParamsDto;

export interface IStdiumType {
  value: string | number;
  text: { en: string; th: string };
}

export interface CompetitionSection extends BaseData {
  setting_sub_competition_id: number;
  section_type: string; // KNOCKOUT,
  competition_section_name_th: string;
  competition_section_name_en: string;
  competition_section_round_name: string;
  competition_section_description_th: string;
  competition_section_description_en: string;
  competition_section_team_join: null;
  groupStages?: any[];
}

export interface SubCompetitionImage extends BaseData {
  setting_sub_competitions: number;
  setting_sub_competition_video_url: null;
  setting_sub_competition_media_name: string;
  setting_sub_competition_media_size: number;
  setting_sub_competition_media_mimetype: string;
  setting_sub_competition_media_type: number;
  setting_sub_competition_media_url: string;
}

export interface StaffImage extends BaseData {
  account_media_video_url: null;
  account_media_file_name: string;
  account_media_name: string;
  account_media_size: string | number;
  account_media_mimetype: string;
  account_media_type: number;
  account_media_document_type: string;
  account_id: string | number;
  account_media_url: string;
}
export interface IImage {
  src: string;
  alt: string;
}
export interface IVideo extends IImage {
  poster: string;
}

export interface Honor {
  id: number;
  honor_name: string;
  honor_year: number;
  setting_team_name_en: string;
  setting_team_name_th: string;
  setting_sub_competition_name_en: string;
  setting_sub_competition_name_th: string;
}

interface HonorDetail {
  honorName: string;
  teamName: string;
  competitionName: string;
}
export interface IHonor {
  honorYear?: number | null;
  en?: {
    honorName: string | null;
    teamName: string | null;
    competitionName: string | null;
  };
  th?: {
    honorName: string | null;
    teamName: string | null;
    competitionName: string | null;
  };
}

export interface IPosition {
  id: string;
  global_config_position_name_th: string;
  global_config_position_name_en: string;
}

export interface INationality {
  country_id: string;
  country_native1_name_th: string;
  country_native1_name_en: string;
}

export interface IVersusMatch {
  id: string;
  setting_stadiums: {
    id: string
    setting_stadium_name_th: string
    setting_stadium_name_en: string
  }
  versus_match_stadium_holder: string
  versus_match_date: string
  versus_match_start_at: string
  versus_match_end_at: string
  versus_match_visitors: string
}